<template>
  <div class="main-container">
    <div class="main">
      <img class="img-css" :src="about_img" alt="" />
      <div class="header">
        <div class="flex align-center justify-between">
          <h1 class="flex align-center">
            <!-- <img class="logo" src="" alt="" /> -->
            <span class="fs-32 base-color">民小生数藏</span>
          </h1>
          <div class="navs base-color fs-20">
            <span>首页</span>
            <span><a href="#about">关于我们</a></span>
            <span><a href="#collection">藏品展示</a></span>
            <!-- <span><a href="#download">下载App</a></span> -->
            <span><a href="#contract">联系我们</a></span>
          </div>
        </div>
        <div class="base-color text-center title">
          <p class="fs-52">传承文化·创新未来</p>
          <p class="fs-32">民小生·年轻人的国潮数字藏品平台</p>
        </div>
      </div>
      <div class="about">
        <div class="content" id="about">
          <div class="title-color fs-26">关于我们</div>
          <div class="line"></div>
          <h2 style="display: none">
            小生，在戏曲中常用作是年轻人的代称，也是古代读书人的谦辞。民小生，携手民生电商集团，集结全民有志青年后生，共同打造国风、国潮数藏平台，立足千年传统文化，迸发数字时代新火花。以区块链加密技术为基石，联合各大IP，不拘泥形式、不限制领域、不干涉创作、不粘贴标签，为Z时代创作者及收藏家全力铸造传承发展并存的、独一无二的先锋数字艺术藏品。
          </h2>
          <div class="base-color fs-20 line-32">
            小生，在戏曲中常用作是年轻人的代称，也是古代读书人的谦辞。民小生，携手民生电商集团，集结全民有志青年后生，共同打造国风、国潮数藏平台，立足千年传统文化，迸发数字时代新火花。以区块链加密技术为基石，联合各大IP，不拘泥形式、不限制领域、不干涉创作、不粘贴标签，为Z时代创作者及收藏家全力铸造传承发展并存的、独一无二的先锋数字艺术藏品。
          </div>
        </div>
      </div>
    </div>
    <div class="collection" id="collection">
      <div class="collection-img">
        <img class="img-css" :src="collection_img" alt="" />
      </div>
      <div class="main">
        <div class="title-color fs-26">藏品展示</div>
        <div class="line"></div>
        <img class="img-css" :src="goods_img" alt="" />
        <div class="base-color fs-26 text-center">《白蛇传》系列数字藏品11月重磅首发</div>
        <!-- <div class="title-color fs-26 mt-100" id="download">下载App</div>
        <div class="line"></div>
         <div class="flex download align-center">
          <img :src="phone_img" alt="" />
          <div class="flex">
            <div style="margin-right: 35px">
              <p class="kehu">Andriod 客户端</p>
              <img class="code" src="" alt="" />
            </div>
            <div>
              <p class="kehu">iOS 客户端</p>
              <img class="code" src="" alt="" />
            </div>
          </div>
        </div> -->
        <div class="title-color fs-26 mt-200" id="contract">联系我们</div>
        <div class="line"></div>
        <div class="fs-20 base-color contract">
          <p class="address">公司地址：</p>
          <p>深圳市南山区后海大道芒果网大厦18楼</p>
          <p>北京市海淀区紫竹院南路20号外文文化创意园</p>
          <div class="flex justify-between align-center">
            <p>武汉市东湖新技术开发区关山大道355号光谷新世界T1写字楼</p>
            <!-- <div class="flex align-center">
              <img :src="email_img" alt="" />
              <p class="ml-8">邮箱：msokd@msok.com</p>
            </div> -->
          </div>
          <div class="flex justify-between align-center">
            <p>成都市武侯区人民南路4段46号上善国际1802室</p>
            <!-- <div class="flex align-center">
              <img :src="telphone_img" alt="" />
              <p class="ml-8">电话：40069-95568转4&emsp;&nbsp;</p>
            </div> -->
          </div>
          <p>电话：40069-95568转4&emsp;&nbsp;</p>
        </div>
      </div>
      <div class="fs-20 base-color footer text-center">
        Copyright © 2022 民小生数藏 msyoupin.com 粤ICP备2021044967号
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      about_img: require('@/assets/images/about_us.png'),
      collection_img: require('@/assets/images/collection.png'),
      goods_img: require('@/assets/images/goods.png'),
      phone_img: require('@/assets/images/phone.png'),
      email_img: require('@/assets/images/email.png'),
      telphone_img: require('@/assets/images/telephone.png'),
    }
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.main-container {
  height: 100%;
  width: 100%;
  padding: 72px 0;
  .main {
    position: relative;
    width: 100%;
    .header {
      position: absolute;
      width: 1200px;
      top: 0;
      left: 50%;
      margin-left: -600px;
      height: 383px;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      .logo {
        background: #fff;
        width: 72px;
        height: 72px;
        margin-right: 32px;
      }
      .navs span {
        margin-left: 64px;
        cursor: pointer;
      }
      .title {
        margin-top: 89px;
      }
    }
    .about {
      position: absolute;
      width: 100%;
      bottom: 0;
      color: chocolate;
      height: 408px;
      background: linear-gradient(
        180deg,
        #000000 0%,
        rgba(0, 0, 0, 0.8) 77.29%,
        rgba(0, 0, 0, 0) 100%
      );
      transform: rotate(180deg);
      .content {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -600px;
        transform: rotate(180deg);
        width: 1200px;
        padding-top: 98px;
      }
      .line-32 {
        line-height: 32px;
        margin-top: 32px;
      }
    }
  }

  .collection {
    position: relative;
    height: 100%;
    .collection-img {
      text-align: center;
      img {
        // margin-right: 100px;
        opacity: 0.7;
        // transform: rotate(5deg);
      }
    }
    .main {
      position: absolute;
      width: 1200px;
      top: 100px;
      left: 50%;
      margin-left: -600px;
      .download {
        margin-top: 44px;
        & > div {
          margin-left: 93px;
        }
        .kehu {
          background: linear-gradient(
              93.07deg,
              #fff0dc -77.02%,
              #dcb276 -24.27%,
              #e5c290 75.44%,
              #fff0dc 99.91%
            ),
            #f0cf86;
          border: 0.5px solid #fff0dc;
          box-shadow: inset 0px 0px 1px #ffffff, inset 0px 0px 2px #ffffff;
          border-radius: 60px;
          height: 45px;
          width: 156px;
          line-height: 45px;
          text-align: center;
          color: #442e0e;
        }
        .code {
          width: 156px;
          height: 156px;
          background: #fff;
          margin-top: 37px;
        }
      }
      .contract {
        line-height: 32px;
        .address {
          margin-top: 32px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
.footer {
  height: 40px;
  line-height: 40px;
}
.line {
  width: 72px;
  height: 4px;
  background: #e5c290;
  margin-top: 16px;
}
a {
  text-decoration: none;
  color: #efefef;
}
.img-css {
  width: 100%;
  height: 100%;
}
h1 {
  font-weight: normal !important;
}
</style>
